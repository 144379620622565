export const COLLECTION_NAME = {
  ACTIONS: 'actions'
} as const;

export type CollectionName = typeof COLLECTION_NAME[keyof typeof COLLECTION_NAME];

export type CollectionItem = {
  collectionMigrationsFolderPath: string;
};

export type CollectionRegistery = Record<CollectionName, CollectionItem>;
