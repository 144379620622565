import {
  type FeedbackWrapper,
  FORM_EVENT_TYPE,
  FORM_RENDERER_EVENT_TYPE,
  FormBuilder
} from '@oms/frontend-foundation';
import { convertToNumber } from '@oms/shared/util';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import {
  type TradeModifyInput,
  type TradeModifyOutput,
  TradeModifyEntryType
} from './trade-modify.form-common';
import {
  tradeModifyContract,
  type TradeModifyContractType,
  type TradeModifyValues
} from './trade-modify.form-contract';
import {
  getTradeInfo,
  getInitialCommonFormValues,
  getInitialRepairOnlyFormValues
} from './trade-modify.form-sanitizers';
import { getTradeFromRepairEvent } from './trade-modify.form-utils';

export const tradeModifyBuilder = FormBuilder.create<TradeModifyInput, TradeModifyOutput>('trade-modify-form')
  .contract<TradeModifyContractType>(tradeModifyContract)
  .type('trade-modify')
  .sanitizer((s) =>
    s
      .input(async function initializeFormValues(input, ctx) {
        const tradeInfo = await getTradeInfo(input, ctx.container);
        let initialFormValues: Partial<TradeModifyValues> = {
          id: input.id
        };

        if (tradeInfo?.type === TradeModifyEntryType.TradeRepair) {
          const tradeData = getTradeFromRepairEvent(tradeInfo.tradeRepair);

          initialFormValues = {
            ...initialFormValues,
            ...getInitialCommonFormValues(tradeData),
            ...getInitialRepairOnlyFormValues(tradeInfo.tradeRepair, tradeData)
          };
        }

        if (tradeInfo?.type === TradeModifyEntryType.TradeModify) {
          initialFormValues = {
            ...initialFormValues,
            ...getInitialCommonFormValues(tradeInfo.trade)
          };
        }

        return {
          hiddenFormInfo: tradeInfo,
          ...initialFormValues
        };
      })
      .output(function sanitizeFormValuesToOutput(formValues) {
        if (
          !formValues?.id ||
          !formValues?.instrument ||
          !formValues?.tradeQuantity ||
          !formValues?.hiddenFormInfo
        ) {
          return;
        }

        const output: TradeModifyOutput['output'] = {
          id: formValues.id
        };
        const { hiddenFormInfo } = formValues;
        const tradeContraAccountId = formValues.tradeContraAccountId?.id;

        output.tradeQuantity = formValues.tradeQuantity ? convertToNumber(formValues.tradeQuantity) : null;
        output.tradePrice = formValues.tradePrice ? convertToNumber(formValues.tradePrice) : null;
        output.tradeDateTime = formValues.tradeDateTime ? formValues.tradeDateTime : undefined;
        if (tradeContraAccountId) {
          output.tradeContraAccount = {
            id: tradeContraAccountId
          };
        }
        output.tradeTagsId = formValues.tradeTags?.map((tag) => tag.id);
        output.settleDate = formValues.settleDate || undefined;
        output.settleType = formValues.settleType;

        if (hiddenFormInfo.type === TradeModifyEntryType.TradeRepair) {
          // set correct trade id for repair case
          output.id = hiddenFormInfo.tradeRepair?.tradeId || '';

          return {
            type: hiddenFormInfo.type,
            output,
            repairTradeId: hiddenFormInfo.tradeRepair?.id || '',
            repairCategory: hiddenFormInfo.tradeRepair?.category
          };
        }

        return {
          type: hiddenFormInfo.type,
          output
        };
      })
  )
  .change(async (event, ctx) => {
    const tradesService = ctx.container.resolve(TradesService);
    const repairService = ctx.container.resolve(TradeRepairRequestService);

    switch (event.type) {
      // TODO: Remove this event and replace with feedback from DryRun API call
      case FORM_EVENT_TYPE.VALUES_CHANGED: {
        if (event.payload.formValues.hiddenFormInfo?.type === TradeModifyEntryType.TradeRepair) {
          const repairErrors = event.payload.formValues.hiddenFormInfo.tradeRepair.errors || [];
          ctx.notify({
            type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
            payload: {
              feedback: repairErrors.map(
                (error) =>
                  ({
                    code: error?.errorCode,
                    level: 'Error',
                    message: error?.message
                  } as FeedbackWrapper)
              )
            }
          });
        }
        break;
      }
      // Turn off SANITIZED_VALUES_CHANGED with DryRun flag untill discussed with BE
      // case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED:
      case FORM_EVENT_TYPE.SUBMIT: {
        const { type, output } = event.payload.output;
        switch (type) {
          case TradeModifyEntryType.TradeModify: {
            return await tradesService.modify(output);
          }
          case TradeModifyEntryType.TradeRepair: {
            const { repairTradeId } = event.payload.output;
            return await repairService.repairCreateTradeRepairRequest(repairTradeId, output);
          }
        }
      }
    }
  });

export type TradeModifyBuilderType = typeof tradeModifyBuilder;

export default tradeModifyBuilder;
