import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { BulkRepairTradeFormInput, BulkRepairTradeFormOutput } from './bulk-repair-trade.form-common';
import {
  type BulkRepairTradeFormContractType,
  type BulkRepairTradeFormValues,
  bulkRepairTradeContract
} from './bulk-repair-trade.form-contract';
import TradeRepairRequestService from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { getSelectedAdditionalFields } from '@app/forms/common/utils/field.utils';

// Bulk Repair Trade Form Builder
export const bulkRepairTradeBuilder = FormBuilder.create<BulkRepairTradeFormInput, BulkRepairTradeFormOutput>(
  'bulk-repair-trade'
)
  .contract<BulkRepairTradeFormContractType>(bulkRepairTradeContract)
  .type('route-order')
  .sanitizer((s) =>
    s
      .input(function sanitize(input) {
        let bulkRepairTradeFormValues: Partial<BulkRepairTradeFormValues> = {
          hiddenFormInfo: {
            tradeRepairRequestIds: input?.tradeRepairRequestIds || []
          }
        };

        return bulkRepairTradeFormValues;
      })
      .output(function sanitize(formValues) {
        const { hiddenFormInfo } = formValues;

        // Build an array of RepairCreateTradeRepairRequests
        const repairCreateTradeRepairRequests = hiddenFormInfo?.tradeRepairRequestIds.map((id: string) => {
          return {
            tradeRepairRequestId: id,
            trade: JSON.stringify({
              tradeCounterpartyId: formValues.tradeCounterparty?.id,
              tags: formValues.tradeTags?.map((tag) => tag?.id)
            })
          };
        });

        return {
          output: {
            data: repairCreateTradeRepairRequests || []
          }
        };
      })
  )
  .change(async (event, ctx) => {
    const selectedFields = await getSelectedAdditionalFields(event.meta.formType, ctx.container);

    const tradeRepairService = ctx.container.resolve(TradeRepairRequestService);

    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const formValues = event.payload.formValues;

        // Create a 'trade' object which the mutation expects as JSON object.
        // If the field in question is a "selected field" shown on the main form,
        // and there's no field value, force the value to be blank.
        const tradeObj = {
          ...(selectedFields.includes('tradeCounterparty')
            ? { tradeCounterpartyId: formValues.tradeCounterparty?.id || '' }
            : {}),
          ...(selectedFields.includes('tradeTags')
            ? { tags: formValues.tradeTags?.map((tag) => tag?.id) || [] }
            : {})
        };

        // Build an array of RepairCreateTradeRepairRequests
        const repairCreateTradeRepairRequests = formValues.hiddenFormInfo?.tradeRepairRequestIds.map(
          (id: string) => {
            return {
              tradeRepairRequestId: id,
              trade: JSON.stringify(tradeObj)
            };
          }
        );

        return await tradeRepairService.bulkRepairTrades({ data: repairCreateTradeRepairRequests || [] });
      }
    }
  });

export default bulkRepairTradeBuilder;
export type BulkRepairTradeFormBuilderType = typeof bulkRepairTradeBuilder;
