export type SipQuote = {
  exchangeId: string;
  quantity?: number;
  price?: number;
};

export type SipData = {
  askPrice?: number;
  bidPrice?: number;
  asks: SipQuote[];
  bids: SipQuote[];
};

export enum OrderSide {
  Buy = 'BUY',
  Sell = 'SELL'
}

export type TradeThroughDetails = {
  tradeThrough?: boolean;
  side?: OrderSide;
  quantity?: number;
  averagePrice?: number;
  bids: SipQuote[];
  asks: SipQuote[];
  malformed: boolean;
};
